import './Home.css';
import Intro from '../../Components/Intro/Intro'
import React, { useState, useRef } from 'react';


import Experience from '../../Components/Experience/Experience';
import Ceridianimg from '../../Assests/dayforce.png';
import reebeeimg from '../../Assests/reebee.png';
import waterloop from '../../Assests/download.png';
import poliviews from '../../Assests/p4.png';
import odaia from '../../Assests/odaia.jpg';
import vidyard from '../../Assests/vidyardlogo.png';
import kikoff from '../../Assests/kikoff-logo.png';
import zon from '../../Assests/zonlogo.webp';

import Project from '../../Components/Project/Project';
import diversify from '../../Assests/diversifyproj.jpg';
import companion from '../../Assests/Companion.jpg';
import yv from '../../Assests/yearview.png';

const CeridianTitle = "Software Developer @ Dayforce";
const CeridianRole = "Winter 2021 Internship with Dayforce on the Infra Team";
const CeridianS1 = "C#";
const CeridianS2 = "Azure DevOps";
const CeridianS3 = "MongoDB";
const CeridianDesc = "Built multiple APIs and designed backend servies to help the company shift towards a micro-service architecture";
const CeridianColor = "#1976E2";
const CeridianImage = Ceridianimg;
const CeridianPath = "/dayforce"

const zonTitle = "Software Engineer @ Amazon";
const zonRole = "Summer 2024 Internship with Amazon in the Stores Team";
const zonS1 = "Java";
const zonS2 = "SQL";
const zonS3 = "AWS";
const zonDesc = "Building services that can process and store petabytes of Amazon E-commerce data";
const zonColor = "#E08D2D";
const zonImage = zon;
const zonPath = "/amazon"

const oTitle = "Software Engineer @ ODAIA";
const oRole = "Summer 2022 Internship with ODAIA on the Infra Team";
const oS1 = "Python";
const oS2 = "AWS";
const oS3 = "Docker";
const oDesc = "Redesigned company cloud infrastructure to ease development processes and ensure site stability";
const oColor = "#0D0F19";
const oImage = odaia;
const oPath = "/odaia"

const vTitle = "Software Developer @ Vidyard";
const vRole = "Winter 2023 Internship with Vidyard on the Integrations Team";
const vS1 = "Node.js";
const vS2 = "TypeScript";
const vS3 = "Ruby";
const vDesc = "Led the development of new webhooks and internal systems to build new integrations really fast and ease sales outreach";
const vColor = "#46db83";
const vImage = vidyard;
const vPath = "/vidyard"

const ReebeeTitle = "Backend Software Engineer @ Reebee";
const ReebeeRole = "Fall 2021 Internship with Reebee on the Backend Platform Team";
const ReebeeS1 = "PHP";
const ReebeeS2 = "MySQL";
const ReebeeS3 = "AWS";
const ReebeeDesc = "Optimizing API endpoints to handle millions of daily requests and aiding the transition from AWS to SnowflakeDB";
const ReebeeColor = "#008CFF";
const ReebeeImage = reebeeimg;
const ReebeePath = "/reebee";

const kTitle = "Software Engineer @ Kikoff";
const kRole = "Fall 2023 Internship with Kikoff on the Platform Team";
const kS1 = "Ruby on Rails";
const kS2 = "MySQL";
const kS3 = "AWS";
const kDesc = "Building financial products that help millions reach their goals";
const kColor = "#00C434";
const kImage = kikoff;
const kPath = "/kikoff";

const WaterloopTitle = "Full Stack Developer @ Waterloop";
const WaterloopRole = "Working as a Full Stack Developer on Waterloop's Backend Team";
const WaterloopS1 = "NodeJS/Express";
const WaterloopS2 = "PostgreSQL";
const WaterloopS3 = "React";
const WaterloopDesc = "Joined Waterloop (Student Design Team) to provide full-stack services for developing server/client side features";
const WaterloopColor = "#041622";
const WaterloopImage = waterloop;
const WaterloopPath = "/waterloop";

const yvtype = "Passion Project";
const yvtitle = "YearView - Relive and Reflect on the Memories You've Made";
const yvs1 = "API Development";
const yvs2 = "MongoDB";
const yvs3 = "JavaScript";
const yvdesc = "Developed a secure photo-storage tool and authentication system that allows users to retain memories throughout a year";
const yvcolor = "#183249";
const yvpath = "https://github.com/parth-p29/YearView";

const dtype = "Recieved 1k+ Users";
const dtitle = "Diversify - Spotify Analytics and New Music Recommendations";
const ds1 = "Flask (Python)";
const ds2 = "Data Analysis";
const ds3 = "Azure";
const ddesc = "Built API endpoints that leveraged thousands of requests from around the world to analyze and display users' Spotify data";
const dcolor = "#8FE083";
const dpath = "https://diversify-application.herokuapp.com/";

const ctype = "Hackathon Project at nwHacks2021";
const ctitle = "Companion - A Social Network for Promoting Mental Health";
const cs1 = "NodeJS/Express";
const cs2 = "Firebase";
const cs3 = "Socket.io";
const cdesc = "A healthy social media network where users interact with others who are going through similar problems as themselves";
const ccolor = "#8977FF";
const cpath = "https://devpost.com/software/companion-m2lg9t";

const atype = "Hackathon Project at Hack the North";
const atitle = "PoliViews - A search engine that retrieves the latest info on politicians";
const as1 = "Flask API";
const as2 = "Beautiful Soup";
const as3 = "React";
const adesc = "Enter a phrase (e.g. climate change) and recieve the vision from each Canadian party leader regarding that query";
const acolor = "#FF7A90";
const apath = "https://devpost.com/software/poliviews";

const Home = () => {

    const experiences = [
        { title: zonTitle, role: zonRole, s1: zonS1, s2: zonS2, s3: zonS3, desc: zonDesc, color: zonColor, image: zonImage, path: zonPath },
        { title: kTitle, role: kRole, s1: kS1, s2: kS2, s3: kS3, desc: kDesc, color: kColor, image: kImage, path: kPath },
        { title: vTitle, role: vRole, s1: vS1, s2: vS2, s3: vS3, desc: vDesc, color: vColor, image: vImage, path: vPath },

        { title: oTitle, role: oRole, s1: oS1, s2: oS2, s3: oS3, desc: oDesc, color: oColor, image: oImage, path: oPath },
        { title: ReebeeTitle, role: ReebeeRole, s1: ReebeeS1, s2: ReebeeS2, s3: ReebeeS3, desc: ReebeeDesc, color: ReebeeColor, image: ReebeeImage, path: ReebeePath },
        { title: CeridianTitle, role: CeridianRole, s1: CeridianS1, s2: CeridianS2, s3: CeridianS3, desc: CeridianDesc, color: CeridianColor, image: CeridianImage, path: CeridianPath },
        { title: WaterloopTitle, role: WaterloopRole, s1: WaterloopS1, s2: WaterloopS2, s3: WaterloopS3, desc: WaterloopDesc, color: WaterloopColor, image: WaterloopImage, path: WaterloopPath },
    ];

    const [showMore, setShowMore] = useState(false);

    const [isAnimating, setIsAnimating] = useState(false);  // To manage fade-out animation

    const myDivRef = useRef(null);

    const toggleShowMore = () => {
        if (showMore) {
            // Scroll first to avoid layout jump
            myDivRef.current.scrollIntoView({ behavior: 'auto' });

            // Then start the fade-out animation if necessary
            setIsAnimating(true);
            setTimeout(() => {
                setShowMore(false);
                setIsAnimating(false);
            }, 300);  // Duration of fade-out animation if any
        } else {
            setShowMore(true);
        }
    };

    return (
        <>
            <Intro/> 

            <div className="title" ref={myDivRef}>
                <h1>Places I've Worked...</h1>
            </div>
            
            <div className="exp first">
                {experiences.slice(0, 3).map(exp => (
                    <Experience key={exp.title} {...exp} />
                ))}
                {showMore && experiences.slice(3).map((exp) => (
                    <div key={exp.title}
                    className={isAnimating  ? 'experience-fade-out' : 'experience-fade-in'}>
                        <Experience {...exp} />
                    </div>
                ))}

            </div>

            <button type="button" onClick={() => { toggleShowMore(); }} className='vibes'>
                {showMore ? 'See Less' : 'See More'}
            </button>

            <div className="title">
                <h1>Featured Projects</h1>
            </div>

            <div className="projs">
                    <Project title={dtype} role={dtitle} s1={ds1} s2={ds2} s3={ds3} desc={ddesc} color={dcolor} image={diversify} path={dpath} />
                    <Project title={atype} role={atitle} s1={as1} s2={as2} s3={as3} desc={adesc} color={acolor} image={poliviews} path={apath} />
                    <Project title={ctype} role={ctitle} s1={cs1} s2={cs2} s3={cs3} desc={cdesc} color={ccolor} image={companion} path={cpath} />
                    <Project title={yvtype} role={yvtitle} s1={yvs1} s2={yvs2} s3={yvs3} desc={yvdesc} color={yvcolor} image={yv} path={yvpath} />
            </div> 

            <div className="more-projs">
                <a href="https://github.com/parth-p29" target="_blank">
                    More Projects
                </a>
            </div> 
        </>
    );
}

export default Home;